import InternalAPI from '../baseAxios.js';
import '../enum';
import '../type';

/**
 * @borrows InternalAPI
 */
export default class RouteOriginAndDestinationRepository extends InternalAPI {
  constructor() {
    super();
    /** @type {string} */
    this.endpoint = '/v1/route';
  }

  /**
   *
   * @param typeSearch {RouteIndex}
   * @param input {String}
   * @return {Promise<{data: RoutePlace[], err: Error}>}
   * @private
   */
  async _fetchData(typeSearch, input) {
    try {
      const res = await this.$axios.get(`${this.endpoint}/${typeSearch}/data?q=${input}`);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data.data, err: null };
        }
      }
      return { data: res.data.data, err: null };
    } catch (err) {
      if (err.response && err.response.data) {
        return { data: [], err: this.setErrorMessage(err.response.data.message) };
      }
      return { data: [], err: this.setErrorMessage(err.message) };
    }
  }

  /**
   *
   * @param typeSearch {RouteIndex|String}
   * @return {Promise<{data: RoutePlace[], err: Error}>}
   */
  async getDefaultAll(typeSearch) {
    return this._fetchData(typeSearch, '');
  }

  /**
   *
   * @param typeSearch {RouteIndex|String}
   * @param inputSearch {string}
   * @return {Promise<{data: RoutePlace[], err: Error}>}
   */
  async getAllBySearch(typeSearch, inputSearch) {
    return this._fetchData(typeSearch, inputSearch);
  }
}
