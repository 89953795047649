import RouteOriginAndDestinationRepository from '../api/routeOriginAndDestination';
import '../enum';
import '../type';
import { changeWordToTitle } from '@/misc/stringMapper';

class RouteOriginAndDestinationUseCase {
  constructor() {
    /** @type {RouteOriginAndDestinationRepository} */
    this.api = new RouteOriginAndDestinationRepository();
    this.rgxFindBracet = /\(([^)]+)\)/g;
  }

  /**
   *
   * @param typeSearch {RouteIndex|String}
   * @return {Promise<{data: RoutePlace[], err: Error}>}
   */
  async getAllDefault(typeSearch) {
    const {data, err} = await this.api.getDefaultAll(typeSearch);
    if (data.length > 0) {
      data.forEach((val) => {
        val.route_code = changeWordToTitle(`${val.route_code}`);
      });
    }
    return {data, err};
  }

  /**
   *
   * @param typeSearch {RouteIndex|String}
   * @param input {string}
   * @return {Promise<{data: RoutePlace[], err: Error}>}
   */
  async getAllBySearch(typeSearch, input) {
    input = input.replace(this.rgxFindBracet, '').trim();
    const {data, err} = await this.api.getAllBySearch(typeSearch, input);
    if (data.length > 0) {
      data.forEach((val) => {
        val.route_code = changeWordToTitle(val.route_code);
      });
    }
    return {data, err};
  }
}

export default new RouteOriginAndDestinationUseCase();
