/**
 * @enum {String}
 * @type {{ORIGIN: string, DESTINATION: string}}
 */
export const RouteIndex = {
  DESTINATION: 'destination',
  ORIGIN: 'origin',
};

/**
 * @enum {String}
 * @type {{BIGPACK: string, BOSSPACK: string, REGPACK: string, JAGOPACK: string, JUMBOPACK: string, OTOPACK150: string, OTOPACK250: string}}
 */
export const ProductName = {
  BOSSPACK: 'BOSSPACK',
  REGPACK: 'REGPACK',
  JAGOPACK: 'JAGOPACK',
  BIGPACK: 'BIGPACK',
  JUMBOPACK: 'JUMBOPACK',
  OTOPACK150: 'OTOPACK150',
  OTOPACK250: 'OTOPACK250',
};

/**
 * @type {
 *      {
 *      BIGPACK: ProductSourceType,
 *      BOSSPACK: ProductSourceType,
 *      REGPACK: ProductSourceType,
 *      JAGOPACK: ProductSourceType,
 *      JUMBOPACK: ProductSourceType,
 *      OTOPACK150: ProductSourceType,
 *      OTOPACK250: ProductSourceType,
 *      }
 * }
 */
export const ProductSource = {
  BOSSPACK: {
    root: 'https://storage.googleapis.com/lionparcel-web-stg/asset/product/asset_bosspack.svg',
    flat: 'https://storage.googleapis.com/lionparcel-web-stg/asset/product/bosspack.svg',
  },
  REGPACK: {
    root: 'https://storage.googleapis.com/lionparcel-web-stg/asset/product/asset_regpack.svg',
    flat: 'https://storage.googleapis.com/lionparcel-web-stg/asset/product/regpack.svg',
  },
  JAGOPACK: {
    root: 'https://storage.googleapis.com/lionparcel-web-stg/asset/product/asset_jagopack.svg',
    flat: 'https://storage.googleapis.com/lionparcel-web-stg/asset/product/jagopack.svg',
  },
  BIGPACK: {
    root: 'https://storage.googleapis.com/lionparcel-web-stg/asset/product/asset_bigpack.svg',
    flat: 'https://storage.googleapis.com/lionparcel-web-stg/asset/product/bigpack.svg',
  },
  JUMBOPACK: {
    root: 'https://storage.googleapis.com/lionparcel-web-stg/asset/product/asset_jumbopack.svg',
    flat: 'https://storage.googleapis.com/lionparcel-web-stg/asset/product/jumbopack.svg',
  },
  OTOPACK150: {
    root: 'https://storage.googleapis.com/lionparcel-web-stg/asset/product/asset_otopack150.svg',
    flat: 'https://storage.googleapis.com/lionparcel-web-stg/asset/product/otopack150.svg',
  },
  OTOPACK250: {
    root: 'https://storage.googleapis.com/lionparcel-web-stg/asset/product/asset_otopack250.svg',
    flat: 'https://storage.googleapis.com/lionparcel-web-stg/asset/product/otopack250.svg',
  },
};

/**
 * @enum {String}
 * @type {{BIGPACK: string, BOSSPACK: string, REGPACK: string, JAGOPACK: string, JUMBOPACK: string, OTOPACK150: string, OTOPACK250: string}}
 */
export const ProductInfo = {
  BOSSPACK: 'Pengiriman Prioritas',
  REGPACK: 'Pengiriman Reguler',
  JAGOPACK: 'Pengiriman Termurah',
  BIGPACK: 'Pengiriman Paket Besar',
  JUMBOPACK: 'Pengiriman Paket Jumbo',
  OTOPACK150: 'Pengiriman Otopack 150',
  OTOPACK250: 'Pengiriman Otopack 250',
};

export const EnumCashOnDelivery = {
  COD: 'cod',
  DFOD: 'dfod',
}
